import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Blocks from "./blocks/Blocks"
import AbstractMedia from "../components/abstractMedia/AbstractMedia"
import roof from "../images/imageWithColumns/bottom.png"
import logoAfdt from "../images/logo.png"
import logoStar from "../images/star.svg"

export default props => {
    let {
        pages,
        media,
        allOpinions,
        googlePlace
    } = props.pageContext

    let page = pages.filter(page => page.url === "/avis");
    page = page[0];

    let leftColumns = []
    let rightColumns = []
    allOpinions.forEach((opinion, index) => {
        if (index % 2 === 0) {
            leftColumns.push(opinion)
        } else {
            rightColumns.push(opinion)
        }
    })

    return (
        <Layout ariane={page.ariane}>
            <SEO titleSEO={page.titleSEO} />
            <Blocks page={page} media={media} />
            <div className="opinion-list">
                <div className="container">
                    <div className="row blocks">
                        <div className="col-lg-9">
                            <div className="roof">
                                <img src={roof} alt="toit" />
                                <h2>Ils le disent</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="d-flex flex-column">
                                        {leftColumns.map(item => (
                                            <div key={item.id} className="block opinion-block">
                                                <div className="date">{item.date.split("-").reverse().join("/")}</div>
                                                <div className="content" dangerouslySetInnerHTML={{ __html: item.description }} />
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        {media && (<AbstractMedia media={media} abstractMedia={item.media} className="img-fluid" />)}
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="description">
                                                            <div className="description-name">{item.name}</div>
                                                            {(item.location && item.location !== "") && <div>{item.location}</div>}
                                                            {(item.job && item.job !== "") && <div>{item.job}</div>}
                                                            {(item.site && item.site !== "") && <a href={item.site}>{item.site}</a>}
                                                        </div>
                                                    </div>
                                                </div>
                                                {(item.urlButton && item.urlButton !== "") && (
                                                    <div className="realisation-btn">
                                                        <a className="btn-green" href={item.urlButton}>
                                                            Voir la réalisation
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex flex-column">
                                        {rightColumns.map(item => (
                                            <div key={item.id} className="block opinion-block">
                                                <div className="date">{item.date.split("-").reverse().join("/")}</div>
                                                <div className="content" dangerouslySetInnerHTML={{ __html: item.description }} />
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        {media && (<AbstractMedia media={media} abstractMedia={item.media} className="img-fluid" />)}
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="description">
                                                            <div className="description-name">{item.name}</div>
                                                            {(item.location && item.location !== "") && <div>{item.location}</div>}
                                                            {(item.job && item.job !== "") && <div>{item.job}</div>}
                                                            {(item.site && item.site !== "") && <a href={item.site}>{item.site}</a>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="title">
                                <h2 className="pt-3 pb-3">Avis Google</h2>
                            </div>
                            {googlePlace && (
                                <div className="review-google review-google-afdt">
                                    <div className="d-flex align-items-center">
                                        <img src={logoAfdt} alt="logo au fil des toits" className="afdt-logo" />
                                        <div className="review-google-content">
                                            <span className="font-weight-bold">{googlePlace.name}</span>
                                            <div className="review-google-rating">
                                                <span className="mr-2">{googlePlace.rating}</span>
                                                {Array.from(Array(Math.round(googlePlace.rating)).keys()).map((_, i) => (
                                                    <img key={`star-${i}`} src={logoStar} alt="star" className="review-google-star" />
                                                ))}
                                            </div>
                                            <span className="review-google-date">{googlePlace.user_ratings_total} avis</span>
                                            <div className="review-google-link">
                                                <a href="https://search.google.com/local/reviews?placeid=ChIJhd6ZAYWmthIR1wlQdKhNJAI" target="_blank" rel="noopener noreferrer">Voir tous les avis</a>
                                                <a href="https://search.google.com/local/writereview?placeid=ChIJhd6ZAYWmthIR1wlQdKhNJAI" target="_blank" rel="noopener noreferrer">Rédiger un avis</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {googlePlace && googlePlace.reviews.map(review => (
                                <div key={review.author_name} className="review-google">
                                    <div className="d-flex align-items-center mb-3">
                                        <img src={review.profile_photo_url} alt={review.author_name} className="review-google-image" />
                                        <div className="review-google-content">
                                            <span className="font-weight-bold mb-1">{review.author_name}</span>
                                            <div className="review-google-rating mb-1">
                                                {Array.from(Array(review.rating).keys()).map((_, i) => (
                                                    <img key={`star-${i}`} src={logoStar} alt="star" className="review-google-star" />
                                                ))}
                                            </div>
                                            <span className="review-google-date">{review.relative_time_description}</span>
                                        </div>
                                    </div>
                                    <p>{review.text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    )
}